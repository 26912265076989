<template>
  <div>
    <h3>About Us</h3>
    <div class="card" v-if="! loading">
      <div class="card-body">
        <form @submit.prevent="submit">
          <div class="form-group">
            <label>Content</label>
            <editor api-key="rn7fdm7awd3udl7l5i2trwivbv6k6udjh9mhinmm653d99uh"
              v-model="content"
              :init="{
                height: 500,
                menubar: true,
                images_upload_handler: image_upload_handler,
                images_upload_url: images_upload_url,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | image',
              }"
            />
          </div>
          <div class="form-group">
            <button class="btn btn-primary">
              <i class="lni lni-save me-2"></i> Save
            </button>
          </div>
        </form>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      content: "",
      loading: true
    };
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get("/api/v1/admin/content/about").then((response) => {
        this.content = response.data.content;
        this.loading = false
      });
    },

    image_upload_handler(blobInfo, success, failure) {
      let data = new FormData();
      data.append('file', blobInfo.blob(), blobInfo.filename());

      this.$axios.post('/api/v1/admin/content/images', data).then(response => {
        success(response.data.location);
      }).catch(error => {
        failure('HTTP Error: ' + error.response.status, { remove: true });
      })
    },

    submit() {
      let data = {
        content: this.content
      }

      this.$axios.put('/api/v1/admin/content/about', data).then(() => {
        this.fetch()
      })
    }
  },

  computed: {
    images_upload_url() {
      return `${process.env.VUE_APP_API_URL}/api/v1/admin/content/images`
    }
  }
};
</script>
